<template>
  <div style="width:100%;height:100%">
    <div class="home_box">
      <!-- 头部搜索框 -->
      <van-search
        v-model="inputValue"
        show-action
        shape="round"
        left-icon=""
        background="#1989fa"
        placeholder="请输入好友手机号"
        @search="onSearch"
      >
        <template #action>
          <van-icon
            name="search"
            @click="onSearch(inputValue)"
            class="seach_icon"
          />
        </template>
      </van-search>
      <!-- 好友列表 -->
      <div
        v-for="(item, index) in list"
        :key="index"
        @click="goTodialog(item, index)"
        class="friendlist_box"
      >
        <div class="firendlist" :data="item.id">
          <van-image round width="3.5rem" height="3rem" :src="item.avatar" />
          <!-- <van-tag
            round
            type="danger"
            class="tag_icon"
            v-show="getUnreadmsg(item.id)"
            style="color:red"
            >{{ getUnreadmsg(item.id) }}</van-tag
          > -->

          <van-cell
            :title="item.nickname"
            :value="item.updateTime"
            :label="item.newMsg || Newmessage.content || item.content"
          />
        </div>
      </div>
      <!-- 好友申请消息 -->
      <div v-for="item in msgList" :key="item.id" class="friendlist_box">
        <div class="firendlist">
          <van-icon name="chat" color="#1989fa" class="chat_icon" />
          <van-cell
            title="好友申请消息"
            :label="item.content || sysMessage.data.message"
            style="width:90%"
          >
            <!-- 使用 title 插槽来自定义标题 -->
            <template #right-icon v-if="btnShow">
              <van-row gutter="10">
                <van-col span="12">
                  <van-button
                    type="danger"
                    size="small"
                    @click="rejectHandle(item.id)"
                    >拒绝</van-button
                  >
                </van-col>
                <van-col span="12">
                  <van-button
                    type="info"
                    size="small"
                    @click="agreeHandle(item.id)"
                    >同意</van-button
                  >
                </van-col>
              </van-row>
            </template>
          </van-cell>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import bottom from '@/views/bottom1'
import { Toast } from 'vant'
import {
  getList,
  applyFirend,
  agreeFriend,
  refuseFriend
} from '../services/home.js'
import { baseUrl } from '../utils/config'
import Msg from '../services/websocket'
// import { mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    bottom
  },
  data() {
    return {
      list: [],
      msgList: [],
      chat: [],
      // time: '',
      findFriend: [],
      inputValue: '',
      btnShow: true,
      addShow: false,
      friend_iphone: '', // 好友手机号
      messageHistory: [], // 历史消息
      sysMessage: '', // 服务端返回的系统消息
      Newmessage: [], // 好友新消息显示红点(未读消息)
      iconShow: false
    }
  },
  computed: {
    // ...mapGetters(['getUnreadmsg'])
  },
  created() {
    this.FriendList()
  },
  mounted() {
    const mmm = new Msg()
    this.$store.state.websock = mmm
    mmm.setHook({
      type: 'applyFriend',
      call: ({ data }) => {
        this.sysMessage = JSON.parse(data)
        const { data: applyData } = JSON.parse(data)
        const isRepe = this.msgList.some(item => {
          return item.id === applyData.id
        })
        if (!isRepe) {
          this.msgList.push(applyData)
        }
      }
    })
    mmm.setHook({
      type: 'getFriends',
      call: ({ data }) => {
        this.list = JSON.parse(data).data
        this.btnShow = false
      }
    })
    mmm.setHook({
      type: 'chatMessage',
      call: ({ data }) => {
        const msg = JSON.parse(data).data
        this.Newmessage.push(msg)
        // this.addUnred({ id: msg.id })
        this.iconShow = true
      }
    })
  },
  methods: {
    // ...mapMutations([
    //   'addUnred',
    //   'init',
    //   'setLocal',
    //   'outHome',
    //   'clearUnreadmsg',
    //   'setCurrentID'
    // ]),
    fromKefu() {
      const { uid } = this.$route.query
      if (!uid) return false
      const kefuData = this.list.find(item => item.id === uid - 0)
      // console.log(kefuData)
      if (kefuData) this.goTodialog(kefuData, uid)
    },
    // 跳转上传执照信息
    GoToDoctor() {
      this.$router.push({ name: 'doctor' })
    },
    // 获取好友列表
    async FriendList() {
      const res = await getList()

      this.msgList = res.data.data.newMsg.list
      res.data.data.friend[0].list.forEach(e => {
        const time = this.$moment(parseInt(e.updateTime * 1000)).format(
          'MM-DD hh:mm:ss'
        )
        e.updateTime = time
      })
      this.list = res.data.data.friend[0].list
      localStorage.setItem('mine', JSON.stringify(res.data.data.mine))
      // this.$store.commit('init', { list: this.list })
      this.list.forEach(e => {
        var obj = e.avatar
        if (obj.indexOf('data:') !== 0) {
          e.avatar = baseUrl + e.avatar
        }
        var dateTime = new Date()
        this.time = dateTime.getMonth() + 1 + '月' + dateTime.getDate() + '日'
      })

      //  判断从客服来
      this.fromKefu()
    },
    // 同意好友
    agreeHandle(id) {
      agreeFriend({
        client_id: localStorage.getItem('clientId'),
        id: id
      }).then(res => {
        if (res.data.code === 0) {
          Toast({ message: '成功添加对方为好友', icon: 'success' })
          this.$router.go(0) // 页面重新加载
        } else {
          // 判断 localStorageRepe 是否含有 与id 相同的id，有则过滤掉 生成新数组 存储在本地
          this.msgList = this.msgList.filter(item => {
            return item.id !== id
          })
          Toast({ message: res.data.msg, icon: 'fail' })
        }
      })
    },
    // 拒绝好友
    rejectHandle(id) {
      refuseFriend({
        client_id: localStorage.getItem('clientId'),
        id: id
      }).then(res => {
        this.$router.go(0) // 页面重新加载
      })
    },
    // 添加好友
    onSearch(value) {
      if (value !== '') {
        applyFirend({
          keyword: value,
          client_id: localStorage.getItem('clientId')
        }).then(res => {
          if (res.data.code === 0) {
            Toast({ message: '好友申请已发送' })
          } else {
            Toast({ message: res.data.msg })
          }
        })
        this.inputValue = ''
      } else {
        Toast({ message: '好友名不能为空', icon: 'fail' })
      }
    },
    // 聊天框
    goTodialog(item, uid) {
      // this.setCurrentID(item.id)
      // this.outHome()
      // this.clearUnreadmsg({ id: item.id })
      // this.setLocal()
      if (uid) {
        this.$router.push({
          path: '/dialog?uid=' + uid
        })
      } else {
        this.$router.push({
          name: 'dialog'
        })
      }

      localStorage.setItem('to', JSON.stringify(item))
    }
  },
  watch: {
    msgList: {
      handler(newVal, oldVal) {
        if (newVal.length !== 0) {
          if (newVal[0].type === 2) {
            this.btnShow = false
          }
        }
      },
      deep: true
    },
    watch: {
      Newmessage: {
        handler(newVal, oldVal) {
          console.log('heihei')
        },
        deep: true
      }
    }
  }
}
</script>

<style scoped>
.icon_show {
  display: none;
}
.home_box {
  padding-bottom: 10vw;
}
/* 新消息标签 */
.tag_icon {
  position: absolute;
  top: 1vw;
  right: 83vw;
  z-index: 222;
}

/* 消息 */
.msg_box {
  padding-top: 2%;
  width: 30vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* 添加按钮 */
.btn_add {
  flex: 1;
  height: 100%;
  background: rgb(79, 192, 141);
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2%;
}
/* 搜索按钮图标 */
.seach_icon {
  font-size: 6vw;
  color: aliceblue;
  display: flex;
  align-items: center; /*垂直居中*/
}
/* 好友列表 */
.friendlist_box {
  margin: 2%;
  border-bottom: 0.1px solid #eeeeee;
  padding: 6px;
}
.firendlist {
  position: relative;
  display: flex;
  /*垂直居中*/
  align-items: center;
}
/* 系统通知头像 */
.chat_icon {
  font-size: 50px !important;
}
/* 单行文本溢出显示省略号 */
.van-cell__label {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.van-cell__title {
  width: 65% !important;
}
.van-search__content {
  display: block;
  width: 70vw !important;
  padding-left: 12px;
  background-color: #f7f8fa;
  /* margin-right: 10vw; */
  border-radius: 30px;
}
.van-search__action {
  margin-right: 2vw;
}
</style>
