<template>
  <div class="bottom">
    <div
      :class="{ active: item.id == current }"
      v-for="item in list"
      :key="item.id"
      @click="btn(item.id)"
    >
      <a :href="item.href">
        <img :src="imgcurrent == item.id ? item.img1 : item.img" alt="" />
        <span>{{ item.title }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 2,
      imgcurrent: 2,
      list: [
        {
          id: 1,
          href: 'https://hrp.weilaihos.com',
          href1: 'http://test.shop.cn',
          // img: require('../assets/images/home.png'),
          img:
            'https://hrp.weilaihos.com/uploads/20200929/08215f7849e9f692c5ec722cad8f0c4a.png',
          img1: require('../assets/images/home-blue.png'),
          title: '健康头条'
        },
        {
          id: 2,
          href: 'https://msg.weilaihos.com/#/',
          href1: 'http://vue.shop.cn:8080',
          img: require('../assets/images/message.png'),
          img1: require('../assets/images/message-blue.png'),
          title: '消息'
        },
        {
          id: 3,
          href: 'https://mall.weilaihos.com/',
          href1: 'https://shop.weilaihos.com/',
          img: require('../assets/images/shop.png'),
          img1: require('../assets/images/shop-blue.png'),
          title: '健康商城'
        },
        {
          id: 4,
          href: 'https://hrp.weilaihos.com/index/user/index.html',
          href1: 'http://test.shop.cn/index/user/index.html',
          img: require('../assets/images/person.png'),
          img1: require('../assets/images/person-blue.png'),
          title: '我的'
        }
      ]
    }
  },
  methods: {
    btn(id) {
      this.current = id
      this.imgcurrent = id
    }
  }
}
</script>

<style scoped>
.bottom {
  position: fixed;
  bottom: 0;
  background: white;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.bottom div {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 110px;
}
.bottom div a {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.bottom div a img {
  height: 20px;
  width: 20px;
}
.bottom div a span {
  color: #333;
  font-size: 12px;
}
.bottom .active a span {
  color: #5ca5ff;
}
</style>
