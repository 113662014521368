import { getOffline, getOnline } from '../services/home.js'
import { wsUrl } from '../utils/config'

export default class Msg {
  static status = {
    websock: null, // 建立的连接
    lockReconnect: false, // 是否真正建立连接
    timeout: 50 * 1000, // 30秒一次心跳
    timeoutObj: 30, // 心跳心跳倒计时
    serverTimeoutObj: null, // 心跳倒计时
    timeoutnum: null // 断开 重连倒计时
  }

  constructor() {
    Msg.status.websock = new WebSocket(wsUrl)
    Msg.status.websock.onopen = this.websocketOnopen.bind(this)
    Msg.status.websock.onerror = this.websocketOnerror.bind(this)
    Msg.status.websock.onmessage = this.websocketOnmessage.bind(this)
    Msg.status.websock.onclose = this.websocketOnclose.bind(this)
    this.wsUrl = wsUrl
    this._hook = {
      chatMessage: [],
      applyFriend: [],
      getFriends: [],
      run: (type, data) => {
        this._hook[type].forEach(call => call(data))
      }
    }
  }

  setHook({ type, call }) {
    this._hook[type].push(call)
  }

  initWebSocket() {
    console.log('重连')
    Msg.status.websock = new WebSocket(this.wsUrl)
    Msg.status.websock.onopen = this.websocketOnopen.bind(this)
    Msg.status.websock.onerror = this.websocketOnerror.bind(this)
    Msg.status.websock.onmessage = this.websocketOnmessage.bind(this)
    Msg.status.websock.onclose = this.websocketOnclose.bind(this)
  }

  reset() {
    // 重置心跳
    var that = this
    // 清除时间
    clearTimeout(that.timeoutObj)
    clearTimeout(that.serverTimeoutObj)
    // 重启心跳
    that.start()
  }

  start() {
    // 开启心跳
    var self = this
    const timeout = Msg.status.timeoutObj
    timeout && clearTimeout(timeout)
    Msg.status.serverTimeoutObj && clearTimeout(Msg.status.serverTimeoutObj)
    Msg.status.timeoutObj = setTimeout(function() {
      // 这里发送一个心跳，后端收到后，返回一个心跳消息
      if (Msg.status.websock.readyState === 1) {
        // 如果连接正常
        Msg.status.websock.send('heartbeat')
      } else {
        // 否则重连
        self.reconnect()
        console.log('心跳错误')
      }
      Msg.status.serverTimeoutObj = setTimeout(function() {
        // 超时关闭
        Msg.status.websock.close()
      }, Msg.status.timeout)
    }, Msg.status.timeout)
  }

  websocketOnopen() {
    this.start()
    console.log('ws连接成功!' + new Date().toLocaleString())
  }

  websocketOnerror(e) {
    console.log('WebSocket连接发生错误')
    this.reconnect()
  }

  websocketOnmessage(e) {
    //  解析对象
    const mydata = JSON.parse(e.data)
    console.log(mydata)
    switch (mydata.type) {
      case 'init':
        localStorage.setItem('clientId', mydata.client_id)
        getOffline({ client_id: mydata.client_id })
        getOnline({ client_id: mydata.client_id })
        this.start()
        break
      // 聊天推送
      case 'chatMessage':
        this._hook.run('chatMessage', e)
        break
      // 同意添加好友
      case 'applyFriend':
        this._hook.run('applyFriend', e)
        // console.log('applyFriend' + mydata)
        break
      case 'online':
        console.log('online' + mydata)
        break
      case 'agreeFriend':
        this._hook.run('agreeFriend', e)
        console.log('添加好友' + mydata)
        break
      case 'getFriends':
        this._hook.run('getFriends', e)
        // window.history.go(0)
        break
    }

    // 收到服务器信息，心跳重置
    this.reset()
  }

  websocketOnclose(e) {
    console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
    var msg = JSON.stringify({
      cmd: 'out_chatting'
    })

    Msg.status.websock.send(msg)
    // 重连
    this.reconnect()
  }

  reconnect() {
    // 重新连接
    if (this.lockReconnect) {
      return false
    }
    this.lockReconnect = true
    // 没连接上会一直重连，设置延迟避免请求过多
    this.timeoutnum && clearTimeout(this.timeoutnum)
    this.timeoutnum = setTimeout(() => {
      // 新连接
      this.initWebSocket()
      this.lockReconnect = false
    }, 10000)
  }

  onerror(e) {
    console.log('出现错误')
    // 重连
    this.reconnect()
  }
}
